import { render, staticRenderFns } from "./AvatarMenu.vue?vue&type=template&id=54f170fa&"
import script from "./AvatarMenu.vue?vue&type=script&lang=ts&"
export * from "./AvatarMenu.vue?vue&type=script&lang=ts&"
import style0 from "./AvatarMenu.vue?vue&type=style&index=0&id=54f170fa&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsIdentidadIcon: require('/Users/pam/Documents/Projects/cmp/cmp/city-manager-platform/components/icons/IdentidadIcon.vue').default,ExpedienteLevelUpDialog: require('/Users/pam/Documents/Projects/cmp/cmp/city-manager-platform/components/expediente/LevelUpDialog.vue').default})
