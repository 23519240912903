import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cf404eea = () => interopDefault(import('../pages/autenticar-error.vue' /* webpackChunkName: "pages/autenticar-error" */))
const _5eed674c = () => interopDefault(import('../themes/RioNegro/pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _2304a9dd = () => interopDefault(import('../pages/auth/index/ChangePasswordFuncionarios.vue' /* webpackChunkName: "pages/auth/index/ChangePasswordFuncionarios" */))
const _01b613a6 = () => interopDefault(import('../pages/auth/index/ChangePasswordInstitucionales.vue' /* webpackChunkName: "pages/auth/index/ChangePasswordInstitucionales" */))
const _4907f326 = () => interopDefault(import('../pages/auth/index/LinkWallet.vue' /* webpackChunkName: "pages/auth/index/LinkWallet" */))
const _65912b58 = () => interopDefault(import('../themes/RioNegro/pages/auth/index/Login.vue' /* webpackChunkName: "pages/auth/index/Login" */))
const _003239ce = () => interopDefault(import('../pages/auth/index/login-funcionarios/index.vue' /* webpackChunkName: "pages/auth/index/login-funcionarios/index" */))
const _40ac1c29 = () => interopDefault(import('../pages/auth/index/login-institucional/index.vue' /* webpackChunkName: "pages/auth/index/login-institucional/index" */))
const _1d688dd8 = () => interopDefault(import('../pages/auth/index/LoginWallet.vue' /* webpackChunkName: "pages/auth/index/LoginWallet" */))
const _34d22638 = () => interopDefault(import('../pages/auth/index/Logout.vue' /* webpackChunkName: "pages/auth/index/Logout" */))
const _020f9e08 = () => interopDefault(import('../themes/RioNegro/pages/auth/index/Register.vue' /* webpackChunkName: "pages/auth/index/Register" */))
const _041efac0 = () => interopDefault(import('../pages/auth/index/ResetPassword.vue' /* webpackChunkName: "pages/auth/index/ResetPassword" */))
const _b02a5fee = () => interopDefault(import('../pages/digital-citizen.vue' /* webpackChunkName: "pages/digital-citizen" */))
const _37abb5e3 = () => interopDefault(import('../pages/digital-citizen-levels.vue' /* webpackChunkName: "pages/digital-citizen-levels" */))
const _0aa002e5 = () => interopDefault(import('../themes/RioNegro/pages/digital-services/index.vue' /* webpackChunkName: "pages/digital-services/index" */))
const _7338261e = () => interopDefault(import('../pages/email-action-handler.vue' /* webpackChunkName: "pages/email-action-handler" */))
const _70ed10e1 = () => interopDefault(import('../pages/expediente.vue' /* webpackChunkName: "pages/expediente" */))
const _5855b556 = () => interopDefault(import('../pages/expediente/apoderados.vue' /* webpackChunkName: "pages/expediente/apoderados" */))
const _3e50d4d5 = () => interopDefault(import('../pages/expediente/appointments/index.vue' /* webpackChunkName: "pages/expediente/appointments/index" */))
const _5c03eba6 = () => interopDefault(import('../pages/expediente/companies.vue' /* webpackChunkName: "pages/expediente/companies" */))
const _6e3e0f40 = () => interopDefault(import('../pages/expediente/identidad.vue' /* webpackChunkName: "pages/expediente/identidad" */))
const _580611d8 = () => interopDefault(import('../pages/expediente/inspections/index.vue' /* webpackChunkName: "pages/expediente/inspections/index" */))
const _82bc89e4 = () => interopDefault(import('../pages/expediente/payments/index.vue' /* webpackChunkName: "pages/expediente/payments/index" */))
const _47f201de = () => interopDefault(import('../pages/expediente/portadocumentos.vue' /* webpackChunkName: "pages/expediente/portadocumentos" */))
const _0ae74e28 = () => interopDefault(import('../pages/expediente/appointments/_appointmentId.vue' /* webpackChunkName: "pages/expediente/appointments/_appointmentId" */))
const _657e8c60 = () => interopDefault(import('../pages/expediente/inspections/_inspectionId.vue' /* webpackChunkName: "pages/expediente/inspections/_inspectionId" */))
const _4d1eed45 = () => interopDefault(import('../pages/expediente/module/_module_id/_submodule_id/index.vue' /* webpackChunkName: "pages/expediente/module/_module_id/_submodule_id/index" */))
const _80ef00ec = () => interopDefault(import('../pages/expediente/module/_module_id/_submodule_id/_details_id.vue' /* webpackChunkName: "pages/expediente/module/_module_id/_submodule_id/_details_id" */))
const _667729ec = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _76b7aa62 = () => interopDefault(import('../pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _2bc244ea = () => interopDefault(import('../pages/forward-email.vue' /* webpackChunkName: "pages/forward-email" */))
const _9455cd9c = () => interopDefault(import('../pages/mercadopago.vue' /* webpackChunkName: "pages/mercadopago" */))
const _74d9461e = () => interopDefault(import('../pages/motordepagos.vue' /* webpackChunkName: "pages/motordepagos" */))
const _0fa75506 = () => interopDefault(import('../pages/notice-of-privacy.vue' /* webpackChunkName: "pages/notice-of-privacy" */))
const _1079c930 = () => interopDefault(import('../pages/persona-moral.vue' /* webpackChunkName: "pages/persona-moral" */))
const _5f0d042e = () => interopDefault(import('../pages/predial.vue' /* webpackChunkName: "pages/predial" */))
const _0f3f4d73 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _1ad13918 = () => interopDefault(import('../pages/server-error.vue' /* webpackChunkName: "pages/server-error" */))
const _7544c68e = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _28373e5f = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _51a919d8 = () => interopDefault(import('../pages/user-guide.vue' /* webpackChunkName: "pages/user-guide" */))
const _348ba8d7 = () => interopDefault(import('../pages/user-guide-functionary.vue' /* webpackChunkName: "pages/user-guide-functionary" */))
const _a96c419c = () => interopDefault(import('../pages/validacion-pago.vue' /* webpackChunkName: "pages/validacion-pago" */))
const _f7d4d18e = () => interopDefault(import('../pages/verificador/index.vue' /* webpackChunkName: "pages/verificador/index" */))
const _2cf08e29 = () => interopDefault(import('../pages/what-is.vue' /* webpackChunkName: "pages/what-is" */))
const _1afb2552 = () => interopDefault(import('../pages/admin/catalogs/index.vue' /* webpackChunkName: "pages/admin/catalogs/index" */))
const _3ce3a4ad = () => interopDefault(import('../pages/admin/changelog/index.vue' /* webpackChunkName: "pages/admin/changelog/index" */))
const _1a22bc99 = () => interopDefault(import('../pages/admin/citas/index.vue' /* webpackChunkName: "pages/admin/citas/index" */))
const _373d9dad = () => interopDefault(import('../pages/admin/dashboard/index.vue' /* webpackChunkName: "pages/admin/dashboard/index" */))
const _764929d4 = () => interopDefault(import('../pages/admin/filters/index.vue' /* webpackChunkName: "pages/admin/filters/index" */))
const _0f81a03e = () => interopDefault(import('../pages/admin/inspectores/index.vue' /* webpackChunkName: "pages/admin/inspectores/index" */))
const _28ebae60 = () => interopDefault(import('../pages/admin/modules/index.vue' /* webpackChunkName: "pages/admin/modules/index" */))
const _5701d56c = () => interopDefault(import('../pages/admin/payments/index.vue' /* webpackChunkName: "pages/admin/payments/index" */))
const _0279faf1 = () => interopDefault(import('../pages/admin/preventiveDelivery/index.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/index" */))
const _b44363fc = () => interopDefault(import('../pages/admin/profile/index.vue' /* webpackChunkName: "pages/admin/profile/index" */))
const _d516f280 = () => interopDefault(import('../pages/admin/settings/index.vue' /* webpackChunkName: "pages/admin/settings/index" */))
const _45cf1a00 = () => interopDefault(import('../pages/auth/CompleteData.vue' /* webpackChunkName: "pages/auth/CompleteData" */))
const _002b0360 = () => interopDefault(import('../pages/citizen/certificates/index.vue' /* webpackChunkName: "pages/citizen/certificates/index" */))
const _ca57c362 = () => interopDefault(import('../pages/tools/ide/index.vue' /* webpackChunkName: "pages/tools/ide/index" */))
const _cd40a336 = () => interopDefault(import('../pages/admin/catalogs/new.vue' /* webpackChunkName: "pages/admin/catalogs/new" */))
const _3c47bc80 = () => interopDefault(import('../pages/admin/citas/configuration/index.vue' /* webpackChunkName: "pages/admin/citas/configuration/index" */))
const _cd24cdd8 = () => interopDefault(import('../pages/admin/forms/builder/index.vue' /* webpackChunkName: "pages/admin/forms/builder/index" */))
const _226e4936 = () => interopDefault(import('../pages/admin/inspectores/configuration/index.vue' /* webpackChunkName: "pages/admin/inspectores/configuration/index" */))
const _35a68607 = () => interopDefault(import('../pages/admin/modules/configuration/index.vue' /* webpackChunkName: "pages/admin/modules/configuration/index" */))
const _73d2f15c = () => interopDefault(import('../pages/admin/operators/inbox.vue' /* webpackChunkName: "pages/admin/operators/inbox" */))
const _32de8b71 = () => interopDefault(import('../pages/admin/payments/configuration/index.vue' /* webpackChunkName: "pages/admin/payments/configuration/index" */))
const _478700d8 = () => interopDefault(import('../pages/admin/preventiveDelivery/configuration/index.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/configuration/index" */))
const _4fbea108 = () => interopDefault(import('../pages/admin/users/configuration/index.vue' /* webpackChunkName: "pages/admin/users/configuration/index" */))
const _7a67f640 = () => interopDefault(import('../pages/citizen/certificates/verification/index.vue' /* webpackChunkName: "pages/citizen/certificates/verification/index" */))
const _12d7c46c = () => interopDefault(import('../pages/admin/inspectores/configuration/catalog.vue' /* webpackChunkName: "pages/admin/inspectores/configuration/catalog" */))
const _34a2591f = () => interopDefault(import('../pages/admin/preventiveDelivery/configuration/catalog.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/configuration/catalog" */))
const _2cf67995 = () => interopDefault(import('../pages/citizen/certificates/form-certs/back_cert.vue' /* webpackChunkName: "pages/citizen/certificates/form-certs/back_cert" */))
const _5d8660e2 = () => interopDefault(import('../pages/admin/citas/configuration/office/new.vue' /* webpackChunkName: "pages/admin/citas/configuration/office/new" */))
const _5381c673 = () => interopDefault(import('../themes/RioNegro/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _37bf7805 = () => interopDefault(import('../pages/admin/citas/configuration/office/_officeId.vue' /* webpackChunkName: "pages/admin/citas/configuration/office/_officeId" */))
const _54e7d337 = () => interopDefault(import('../pages/admin/catalogs/terms/_id.vue' /* webpackChunkName: "pages/admin/catalogs/terms/_id" */))
const _b9794298 = () => interopDefault(import('../pages/admin/citas/details/_detailsId.vue' /* webpackChunkName: "pages/admin/citas/details/_detailsId" */))
const _aac381b6 = () => interopDefault(import('../pages/admin/forms/builder/_builderid.vue' /* webpackChunkName: "pages/admin/forms/builder/_builderid" */))
const _32c8e305 = () => interopDefault(import('../pages/admin/forms/details/_detailsid.vue' /* webpackChunkName: "pages/admin/forms/details/_detailsid" */))
const _5efc57d4 = () => interopDefault(import('../pages/admin/inspectores/inspectionDetailsId/_inspectionDetailsId.vue' /* webpackChunkName: "pages/admin/inspectores/inspectionDetailsId/_inspectionDetailsId" */))
const _c4a1a436 = () => interopDefault(import('../pages/admin/operators/details/_detailsid.vue' /* webpackChunkName: "pages/admin/operators/details/_detailsid" */))
const _93f1f642 = () => interopDefault(import('../pages/admin/preventiveDelivery/preventiveDeliveryId/_preventiveDeliveryId.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/preventiveDeliveryId/_preventiveDeliveryId" */))
const _55669fa9 = () => interopDefault(import('../pages/citizen/certificates/digital-identity/_cert.vue' /* webpackChunkName: "pages/citizen/certificates/digital-identity/_cert" */))
const _461f59c8 = () => interopDefault(import('../pages/citizen/certificates/form-certs/_cert.vue' /* webpackChunkName: "pages/citizen/certificates/form-certs/_cert" */))
const _56cd3c08 = () => interopDefault(import('../pages/admin/forms/answers/_form/_answerId.vue' /* webpackChunkName: "pages/admin/forms/answers/_form/_answerId" */))
const _7d743c9c = () => interopDefault(import('../pages/admin/catalogs/terms/_.vue' /* webpackChunkName: "pages/admin/catalogs/terms/_" */))
const _f4ac4d02 = () => interopDefault(import('../pages/admin/catalogs/_id.vue' /* webpackChunkName: "pages/admin/catalogs/_id" */))
const _eb629f98 = () => interopDefault(import('../pages/admin/forms/_formid.vue' /* webpackChunkName: "pages/admin/forms/_formid" */))
const _3f89c3d2 = () => interopDefault(import('../pages/admin/modules/_moduleId/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/index" */))
const _458abd40 = () => interopDefault(import('../pages/admin/modules/_moduleId/playlist/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/playlist/index" */))
const _762f418c = () => interopDefault(import('../pages/admin/modules/_moduleId/playlist/_playlistId.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/playlist/_playlistId" */))
const _4e5a5bb6 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/index" */))
const _cd61ab26 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/inbox/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/inbox/index" */))
const _34d33879 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/uploads/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/uploads/index" */))
const _653dead2 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/details/_detailsid.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/details/_detailsid" */))
const _0cf590fe = () => interopDefault(import('../pages/certificates/_id.vue' /* webpackChunkName: "pages/certificates/_id" */))
const _45cea0ea = () => interopDefault(import('../pages/finalDocument/_finalDocument.vue' /* webpackChunkName: "pages/finalDocument/_finalDocument" */))
const _0f2a4c3a = () => interopDefault(import('../pages/payments/_paymentId.vue' /* webpackChunkName: "pages/payments/_paymentId" */))
const _04cb6297 = () => interopDefault(import('../pages/playlist/_id.vue' /* webpackChunkName: "pages/playlist/_id" */))
const _4461b1eb = () => interopDefault(import('../themes/RioNegro/pages/modules/_module/_submodule/index.vue' /* webpackChunkName: "pages/modules/_module/_submodule/index" */))
const _30fe4fd3 = () => interopDefault(import('../themes/RioNegro/pages/modules/_module/_submodule/_postId.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId" */))
const _975327d4 = () => interopDefault(import('../themes/RioNegro/pages/modules/_module/_submodule/_postId/index.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/index" */))
const _9478f964 = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/appointment.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/appointment" */))
const _4fae352b = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/description.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/description" */))
const _2bd95453 = () => interopDefault(import('../themes/RioNegro/pages/modules/_module/_submodule/_postId/request.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/request" */))
const _35aee3eb = () => interopDefault(import('../themes/RioNegro/pages/modules/_module/_submodule/_postId/steps.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/steps" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/autenticar-error",
    component: _cf404eea,
    name: "autenticar-error"
  }, {
    path: "/auth",
    component: _5eed674c,
    name: "auth",
    children: [{
      path: "ChangePasswordFuncionarios",
      component: _2304a9dd,
      name: "auth-index-ChangePasswordFuncionarios"
    }, {
      path: "ChangePasswordInstitucionales",
      component: _01b613a6,
      name: "auth-index-ChangePasswordInstitucionales"
    }, {
      path: "LinkWallet",
      component: _4907f326,
      name: "auth-index-LinkWallet"
    }, {
      path: "Login",
      component: _65912b58,
      name: "auth-index-Login"
    }, {
      path: "login-funcionarios",
      component: _003239ce,
      name: "auth-index-login-funcionarios"
    }, {
      path: "login-institucional",
      component: _40ac1c29,
      name: "auth-index-login-institucional"
    }, {
      path: "LoginWallet",
      component: _1d688dd8,
      name: "auth-index-LoginWallet"
    }, {
      path: "Logout",
      component: _34d22638,
      name: "auth-index-Logout"
    }, {
      path: "Register",
      component: _020f9e08,
      name: "auth-index-Register"
    }, {
      path: "ResetPassword",
      component: _041efac0,
      name: "auth-index-ResetPassword"
    }]
  }, {
    path: "/digital-citizen",
    component: _b02a5fee,
    name: "digital-citizen"
  }, {
    path: "/digital-citizen-levels",
    component: _37abb5e3,
    name: "digital-citizen-levels"
  }, {
    path: "/digital-services",
    component: _0aa002e5,
    name: "digital-services"
  }, {
    path: "/email-action-handler",
    component: _7338261e,
    name: "email-action-handler"
  }, {
    path: "/expediente",
    component: _70ed10e1,
    name: "expediente",
    children: [{
      path: "apoderados",
      component: _5855b556,
      name: "expediente-apoderados"
    }, {
      path: "appointments",
      component: _3e50d4d5,
      name: "expediente-appointments"
    }, {
      path: "companies",
      component: _5c03eba6,
      name: "expediente-companies"
    }, {
      path: "identidad",
      component: _6e3e0f40,
      name: "expediente-identidad"
    }, {
      path: "inspections",
      component: _580611d8,
      name: "expediente-inspections"
    }, {
      path: "payments",
      component: _82bc89e4,
      name: "expediente-payments"
    }, {
      path: "portadocumentos",
      component: _47f201de,
      name: "expediente-portadocumentos"
    }, {
      path: "appointments/:appointmentId",
      component: _0ae74e28,
      name: "expediente-appointments-appointmentId"
    }, {
      path: "inspections/:inspectionId",
      component: _657e8c60,
      name: "expediente-inspections-inspectionId"
    }, {
      path: "module/:module_id?/:submodule_id",
      component: _4d1eed45,
      name: "expediente-module-module_id-submodule_id"
    }, {
      path: "module/:module_id?/:submodule_id?/:details_id",
      component: _80ef00ec,
      name: "expediente-module-module_id-submodule_id-details_id"
    }]
  }, {
    path: "/faq",
    component: _667729ec,
    name: "faq"
  }, {
    path: "/forbidden",
    component: _76b7aa62,
    name: "forbidden"
  }, {
    path: "/forward-email",
    component: _2bc244ea,
    name: "forward-email"
  }, {
    path: "/mercadopago",
    component: _9455cd9c,
    name: "mercadopago"
  }, {
    path: "/motordepagos",
    component: _74d9461e,
    name: "motordepagos"
  }, {
    path: "/notice-of-privacy",
    component: _0fa75506,
    name: "notice-of-privacy"
  }, {
    path: "/persona-moral",
    component: _1079c930,
    name: "persona-moral"
  }, {
    path: "/predial",
    component: _5f0d042e,
    name: "predial"
  }, {
    path: "/reset-password",
    component: _0f3f4d73,
    name: "reset-password"
  }, {
    path: "/server-error",
    component: _1ad13918,
    name: "server-error"
  }, {
    path: "/terms-and-conditions",
    component: _7544c68e,
    name: "terms-and-conditions"
  }, {
    path: "/terms-of-service",
    component: _28373e5f,
    name: "terms-of-service"
  }, {
    path: "/user-guide",
    component: _51a919d8,
    name: "user-guide"
  }, {
    path: "/user-guide-functionary",
    component: _348ba8d7,
    name: "user-guide-functionary"
  }, {
    path: "/validacion-pago",
    component: _a96c419c,
    name: "validacion-pago"
  }, {
    path: "/verificador",
    component: _f7d4d18e,
    name: "verificador"
  }, {
    path: "/what-is",
    component: _2cf08e29,
    name: "what-is"
  }, {
    path: "/admin/catalogs",
    component: _1afb2552,
    name: "admin-catalogs"
  }, {
    path: "/admin/changelog",
    component: _3ce3a4ad,
    name: "admin-changelog"
  }, {
    path: "/admin/citas",
    component: _1a22bc99,
    name: "admin-citas"
  }, {
    path: "/admin/dashboard",
    component: _373d9dad,
    name: "admin-dashboard"
  }, {
    path: "/admin/filters",
    component: _764929d4,
    name: "admin-filters"
  }, {
    path: "/admin/inspectores",
    component: _0f81a03e,
    name: "admin-inspectores"
  }, {
    path: "/admin/modules",
    component: _28ebae60,
    name: "admin-modules"
  }, {
    path: "/admin/payments",
    component: _5701d56c,
    name: "admin-payments"
  }, {
    path: "/admin/preventiveDelivery",
    component: _0279faf1,
    name: "admin-preventiveDelivery"
  }, {
    path: "/admin/profile",
    component: _b44363fc,
    name: "admin-profile"
  }, {
    path: "/admin/settings",
    component: _d516f280,
    name: "admin-settings"
  }, {
    path: "/auth/CompleteData",
    component: _45cf1a00,
    name: "auth-CompleteData"
  }, {
    path: "/citizen/certificates",
    component: _002b0360,
    name: "citizen-certificates"
  }, {
    path: "/tools/ide",
    component: _ca57c362,
    name: "tools-ide"
  }, {
    path: "/admin/catalogs/new",
    component: _cd40a336,
    name: "admin-catalogs-new"
  }, {
    path: "/admin/citas/configuration",
    component: _3c47bc80,
    name: "admin-citas-configuration"
  }, {
    path: "/admin/forms/builder",
    component: _cd24cdd8,
    name: "admin-forms-builder"
  }, {
    path: "/admin/inspectores/configuration",
    component: _226e4936,
    name: "admin-inspectores-configuration"
  }, {
    path: "/admin/modules/configuration",
    component: _35a68607,
    name: "admin-modules-configuration"
  }, {
    path: "/admin/operators/inbox",
    component: _73d2f15c,
    name: "admin-operators-inbox"
  }, {
    path: "/admin/payments/configuration",
    component: _32de8b71,
    name: "admin-payments-configuration"
  }, {
    path: "/admin/preventiveDelivery/configuration",
    component: _478700d8,
    name: "admin-preventiveDelivery-configuration"
  }, {
    path: "/admin/users/configuration",
    component: _4fbea108,
    name: "admin-users-configuration"
  }, {
    path: "/citizen/certificates/verification",
    component: _7a67f640,
    name: "citizen-certificates-verification"
  }, {
    path: "/admin/inspectores/configuration/catalog",
    component: _12d7c46c,
    name: "admin-inspectores-configuration-catalog"
  }, {
    path: "/admin/preventiveDelivery/configuration/catalog",
    component: _34a2591f,
    name: "admin-preventiveDelivery-configuration-catalog"
  }, {
    path: "/citizen/certificates/form-certs/back_cert",
    component: _2cf67995,
    name: "citizen-certificates-form-certs-back_cert"
  }, {
    path: "/admin/citas/configuration/office/new",
    component: _5d8660e2,
    name: "admin-citas-configuration-office-new"
  }, {
    path: "/",
    component: _5381c673,
    name: "index"
  }, {
    path: "/admin/citas/configuration/office/:officeId?",
    component: _37bf7805,
    name: "admin-citas-configuration-office-officeId"
  }, {
    path: "/admin/catalogs/terms/:id?",
    component: _54e7d337,
    name: "admin-catalogs-terms-id"
  }, {
    path: "/admin/citas/details/:detailsId?",
    component: _b9794298,
    name: "admin-citas-details-detailsId"
  }, {
    path: "/admin/forms/builder/:builderid",
    component: _aac381b6,
    name: "admin-forms-builder-builderid"
  }, {
    path: "/admin/forms/details/:detailsid?",
    component: _32c8e305,
    name: "admin-forms-details-detailsid"
  }, {
    path: "/admin/inspectores/inspectionDetailsId/:inspectionDetailsId?",
    component: _5efc57d4,
    name: "admin-inspectores-inspectionDetailsId-inspectionDetailsId"
  }, {
    path: "/admin/operators/details/:detailsid?",
    component: _c4a1a436,
    name: "admin-operators-details-detailsid"
  }, {
    path: "/admin/preventiveDelivery/preventiveDeliveryId/:preventiveDeliveryId?",
    component: _93f1f642,
    name: "admin-preventiveDelivery-preventiveDeliveryId-preventiveDeliveryId"
  }, {
    path: "/citizen/certificates/digital-identity/:cert?",
    component: _55669fa9,
    name: "citizen-certificates-digital-identity-cert"
  }, {
    path: "/citizen/certificates/form-certs/:cert?",
    component: _461f59c8,
    name: "citizen-certificates-form-certs-cert"
  }, {
    path: "/admin/forms/answers/:form?/:answerId?",
    component: _56cd3c08,
    name: "admin-forms-answers-form-answerId"
  }, {
    path: "/admin/catalogs/terms/*",
    component: _7d743c9c,
    name: "admin-catalogs-terms-all"
  }, {
    path: "/admin/catalogs/:id",
    component: _f4ac4d02,
    name: "admin-catalogs-id"
  }, {
    path: "/admin/forms/:formid?",
    component: _eb629f98,
    name: "admin-forms-formid"
  }, {
    path: "/admin/modules/:moduleId",
    component: _3f89c3d2,
    name: "admin-modules-moduleId"
  }, {
    path: "/admin/modules/:moduleId/playlist",
    component: _458abd40,
    name: "admin-modules-moduleId-playlist"
  }, {
    path: "/admin/modules/:moduleId/playlist/:playlistId",
    component: _762f418c,
    name: "admin-modules-moduleId-playlist-playlistId"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId",
    component: _4e5a5bb6,
    name: "admin-modules-moduleId-submoduleId"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/inbox",
    component: _cd61ab26,
    name: "admin-modules-moduleId-submoduleId-inbox"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/uploads",
    component: _34d33879,
    name: "admin-modules-moduleId-submoduleId-uploads"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/details/:detailsid?",
    component: _653dead2,
    name: "admin-modules-moduleId-submoduleId-details-detailsid"
  }, {
    path: "/certificates/:id?",
    component: _0cf590fe,
    name: "certificates-id"
  }, {
    path: "/finalDocument/:finalDocument?",
    component: _45cea0ea,
    name: "finalDocument-finalDocument"
  }, {
    path: "/payments/:paymentId?",
    component: _0f2a4c3a,
    name: "payments-paymentId"
  }, {
    path: "/playlist/:id?",
    component: _04cb6297,
    name: "playlist-id"
  }, {
    path: "/modules/:module?/:submodule",
    component: _4461b1eb,
    name: "modules-module-submodule"
  }, {
    path: "/modules/:module?/:submodule?/:postId",
    component: _30fe4fd3,
    children: [{
      path: "",
      component: _975327d4,
      name: "modules-module-submodule-postId"
    }, {
      path: "appointment",
      component: _9478f964,
      name: "modules-module-submodule-postId-appointment"
    }, {
      path: "description",
      component: _4fae352b,
      name: "modules-module-submodule-postId-description"
    }, {
      path: "request",
      component: _2bd95453,
      name: "modules-module-submodule-postId-request"
    }, {
      path: "steps",
      component: _35aee3eb,
      name: "modules-module-submodule-postId-steps"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
