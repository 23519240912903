import { render, staticRenderFns } from "./TextEditor.vue?vue&type=template&id=276825be&scoped=true&"
import script from "./TextEditor.vue?vue&type=script&lang=js&"
export * from "./TextEditor.vue?vue&type=script&lang=js&"
import style0 from "./TextEditor.vue?vue&type=style&index=0&id=276825be&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "276825be",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIEditorMenuBar: require('/Users/pam/Documents/Projects/cmp/cmp/city-manager-platform/components/UI/Editor/MenuBar.vue').default})
